
import * as storyblokRuntime$uW25YfgKUj from '/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as ipxStaticRuntime$8qYxGvs6jt from '/home/runner/work/es-cms-pages/es-cms-pages/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 0,
    "sm": 575,
    "md": 767,
    "lg": 991,
    "xl": 1199,
    "xxl": 1899,
    "2xl": 1536,
    "xxxl": 2500
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$uW25YfgKUj, defaults: {"baseURL":"https://a-us.storyblok.com"} },
  ['ipxStatic']: { provider: ipxStaticRuntime$8qYxGvs6jt, defaults: {} }
}
        